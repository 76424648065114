<template>
  <v-container class="pa-0" fluid>
    <transition-group appear name="fadeGroup" mode="out-in">
      <v-row key="a" class="ma-0 grey lighten-3" no-gutters>
        <v-col class="d-block d-md-flex align-center grey lighten-3 pa-6" cols="12">
          <v-responsive class="mx-auto px-3 px-md-12">
            <p class="text-justify">
              МetaMorfosi – это авторский проект психолога, арт-терапевта и художницы Надежды
              Навроцкой. Это пространство погружения в творчество, пространство самопомощи и
              удивительных трансформаций. Символ нашего проекта – Голубой муравьед. Почему
              муравьед?<br />
              Это персонаж одной из живописных работ Надежды «Голубые муравьеды и невидимый Дали».
              Муравьед – символ свободы самовыражения, движения вперед и креативности. Того, к чему
              мы призываем всех наших участников. И наши участники действительно потрясающе
              интересные творческие люди! <br />
              MetaMorfosi активно развивается с 2017 года, а с 2020 года и на специальной
              виртуальной платформе. С ним удобно взаимодействовать участнику с любым опытом и на
              любом жизненном этапе. Специальные навыки не нужны. Лишь немного вашего времени и
              внимания. MetaMorfosi – это пространство арт-терапевтических игр и студий на самые
              разные темы. Каждая из них уникальна, т.к. разработана именно для этого проекта. Здесь
              вы найдете
              <router-link to="/studios"><b>арт-студии</b></router-link>
              для самостоятельного погружения. Это универсальный способ самостоятельной работы с
              запросом любого масштаба. Мини-студии - это работа с запросом в творческой и игровой
              форме. Большие студии - более терапевтический формат, разработан для работы с более
              глубинными, основополагающими вопросами. <br />
              И, конечно, самое прекрасное - наша виртуальная
              <router-link to="/galleryList"><b>галерея</b></router-link
              >, которая постоянно пополняется работами наших участников с самых разных
              арт-проектов.
            </p>
            <hr />
          </v-responsive>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2FIMG_30381%202.jpg?alt=media&token=daaf1189-f05c-4394-9520-2a6ccb84912d"
          />
        </v-col>
        <v-col class="d-block d-md-flex align-center grey lighten-3 pa-6" cols="12" md="8">
          <v-responsive class="mx-auto px-3 px-md-12">
            <h1
              style="color: black; white-space: pre-line"
              class="mb-6"
              :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
            >
              Надежда Навроцкая<br />
            </h1>
            <p class="text-justify">
              Основатель проекта Metamorfosi и автор студий Надежда Навроцкая является примером
              того, что можно всё, когда речь идёт о раскрытии человеческого потенциала. Оставив за
              плечами профессиональную деятельность психолога в Беларуси, а также собственную
              консультационную гомеопатическую и остеопатическую практику, Надежда вернулась к мечте
              детства - фотографии и искусству. Применив свой опыт работы с различными материалами в
              искусстве и нейрографике, а также ряд авторских арт-терапевтических техник, Надежда
              создала серию уникальных студий, которые не просто совмещают в себе элементы
              креативной терапии и психологии, но и мягко способствуют снятию внутренних защит для
              раскрытия творческого потенциала клиентов. В основе творческой деятельности Надежды
              лежат принципы жизни как удовольствия, ненасильственного развития и изначальной
              цельности клиентов. Искренняя вера в человеческий потенциал и стремление на личном
              примере показать, что происходит с нашей жизнью, когда импульс сочетается с действием,
              позволяют Надежде создавать уникальные студии проекта Metamorfosi.
            </p>
            <hr />
          </v-responsive>
        </v-col>

        <v-col cols="12" md="4">
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2F24ca61d7-a1c9-454a-beee-dd04be27b8e6.JPG?alt=media&token=38ce56ba-11c4-4c74-bc4c-a9acf322996b"
          />
        </v-col>
        <v-col class="d-block d-md-flex align-center grey lighten-3 pa-6" cols="12" md="8">
          <v-responsive class="mx-auto px-3 px-md-12">
            <h1
              style="color: black; white-space: pre-line"
              class="mb-6"
              :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
            >
              Андрей Навроцкий<br />
            </h1>
            <p class="text-justify">
              Разработчик платформы Metamorfosi и главный администратор сайта Андрей Навроцкий.
              После переезда в Италию Андрей кардинально сменил сферу деятельности, использовав свои
              технические навыки и знания, и создал многофункциональный сайт проекта, благодаря
              которому у клиентов есть возможность участвовать в студиях в различных форматах и
              получать удовольствие от бонусных программ. Внимание к деталям, честность и уникальная
              работоспособность сделали Андрея незаменимым сотрудником команды Metamorfosi.
              JavaScript, HTML, CSS, а также VueJS и NodeJS - и это не полный перечень технических
              навыков, позволяющих Андрею создавать сложные продукты и адаптировать их под постоянно
              меняющиеся требования клиентов и среды.
            </p>
            <hr />
          </v-responsive>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2FIMG_7902.JPG?alt=media&token=3f4514bd-ce27-455b-b5b1-4e728a1ff041"
          />
        </v-col>
        <v-col class="d-block d-md-flex align-center grey lighten-3 pa-6" cols="12" md="8">
          <v-responsive class="mx-auto px-3 px-md-12">
            <h1
              style="color: black; white-space: pre-line"
              class="mb-6"
              :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
            >
              Алена Кушнир<br />
            </h1>
            <p class="text-justify">
              Менеджер платформы MetaMorfosi Алена Кушнир Что будет, если соединить 17 лет работы в
              крупных web-проектах на разных позициях, от модератора форума до главного-редактора,
              опыт куратора арт-проектов и международных фотоконкурсов, и врожденное стремление
              упорядочивать и структурировать? Получится то уникальное сочетание навыков и личных
              качеств, которые привели Алену на позицию менеджера и автора ряда текстов в социальных
              сетях MetaMorfosi. Сопровождая платформу с первого дня ее работы, именно Алена знает
              ее историю и все трансформации. Это помогает сопровождать и поддерживать участников
              студий, отвечать на вопросы подписчиков в социальных сетях, создавать атмосферу
              комфорта для каждого клиента. Пожалуй, нет той ситуации или того вопроса, где Алена не
              предложила бы самое оптимальное решение. Команда платформы MetaMorfosi - это постоянно
              действующий генератор идей, форматов, экспериментов. «Отличная идея! Я предложу
              оптимальный вариант, как это реализовать» - ответ Алены на 99% предложений в рабочем
              чате MetaMorfosi.
            </p>
            <hr />
          </v-responsive>
        </v-col>
      </v-row>
      <v-row key="b" justify="center" class="grey lighten-3">
        <v-col cols="12" md="6">
          <feedback :message-subject="'O нас'"
            ><v-card-text style="color: #FB8C00" class="headline text-center"
              >Остались вопросы? <br />
              Напишите нам</v-card-text
            ></feedback
          >
        </v-col>
      </v-row>
    </transition-group>
  </v-container>
</template>

<script>
export default {
  name: 'AboutUs',
  metaInfo: {
    title: 'О нас'
  },
  components: {
    Feedback: () => import('../components/shared/Feedback')
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
